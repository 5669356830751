
const seoDefaulutImg = `${process.env.resourceUrl}images/seo/seo.jpg`;

export const seoData = {
  en: {
    home: {
      title: `XREAL - Building Augmented Reality for Everyone`,
      description: `XREAL AR glasses is all about making reality better for everyone, everywhere，and bring you an immersive brand new visual experience.`,
      keywords: '',
      image: seoDefaulutImg,
    },
    about: {
      title: `About Us - XREAL`,
      description: `Aiming to build the next generation user interaction, XREAL has been at the forefront of developing consumer-facing mixed reality wearables since 2017.`,
      keywords: '',
      image: seoDefaulutImg,
    },
    news: {
      title: `News - XREAL`,
      description: `Find all the latest news about XREAL, including product updates, company news and upcoming events.`,
      keywords: '',
      image: seoDefaulutImg,
    },
    user: {
      title: `User`,
      description: ``,
      keywords: '',
      image: seoDefaulutImg,
    },
    404: {
      title: `404: Not found`,
      description: `404`,
      keywords: '',
      image: seoDefaulutImg,
    },
    nebula: {
      title: `Nebula - Software Required for Virtual Desktop and AR Space Experience`,
      description: `Nebula, XREAL's proprietary software that provides an operating environment for AR apps built with the XREAL SDK. Required for Virtual Desktop and AR Space experience.`,
      keywords: '',
      image: seoDefaulutImg,
    },
    redirect: {
      title: `redirect: login`,
      description: ``,
      keywords: '',
      image: seoDefaulutImg,
    },
    adapter: {
      title: `XREAL Adapter | Upgrade Your Connectivity`,
      description: `XREAL Adapter, Connects to iPhone via Lightning to HDMI Adapter, Compatible with Nintendo Switch, Playstation 4Slim/5 and Xbox Series X/S`,
      keywords: '',
      image: seoDefaulutImg,
    },
    air: {
      title: `XREAL Air | Elevate Your AR Experience with Cutting-Edge Smart Glasses`,
      description: `Discover the power of XREAL Air AR glasses and step into a world where virtual meets real. Elevate your visual experience.`,
      keywords: '',
      image: seoDefaulutImg,
    },
    air_stores: {
      title: `XREAL Air`,
      description: `Japanese Store`,
      keywords: '',
      image: seoDefaulutImg,
    },
    ar_lab: {
      title: `AR Lab`,
      description: ``,
      keywords: '',
      image: seoDefaulutImg,
    },
    beam: {
      title: `XREAL Beam - The Ultimate Spatial Display Solution`,
      description: `XREAL Beam, delivering an unmatched Spatial Display experience across all your devices. Compatible with pretty much every mobile phone, PC and gaming consoles`,
      keywords: '',
      image: seoDefaulutImg,
    },
    career: {
      title: `Career - XREAL`,
      description: ``,
      keywords: '',
      image: seoDefaulutImg,
    },
    compatibility: {
      title: `Compatibility`,
      description: `compatible`,
      keywords: '',
      image: seoDefaulutImg,
    },
    contact_us: {
      title: `Contact Us`,
      description: ``,
      keywords: '',
      image: seoDefaulutImg,
    },
    experience: {
      title: `XREAL Experience | Immersive Entertainment Experience`,
      description: `Step into a world of limitless possibilities with XREAL AR glasses, where XREAL Experience takes you on an immersive journey like never before.`,
      keywords: '',
      image: seoDefaulutImg,
    },
    faq: {
      title: `FAQ`,
      description: ``,
      keywords: '',
      image: seoDefaulutImg,
    },
    light: {
      title: `XREAL Light | Lightweight AR Smart Glasses`,
      description: `Experience augmented reality like never before with XREAL Light, the lightweight AR glasses that redefine comfort and immersion.`,
      keywords: '',
      image: seoDefaulutImg,
    },
    privacy_policy: {
      title: `Privacy Policy`,
      description: ``,
      keywords: '',
      image: seoDefaulutImg,
    },
    specifications: {
      title: `Specifications`,
      description: `compatible`,
      keywords: '',
      image: seoDefaulutImg,
    },
    support: {
      title: `Support - XREAL`,
      description: ``,
      keywords: '',
      image: seoDefaulutImg,
    },
    update: {
      title: `Update`,
      description: ``,
      keywords: '',
      image: seoDefaulutImg,
    },
    // 未使用
    product: {
      title: `XREAL Light – Ready-to-wear Mixed Reality Glasses`,
      description: `At just 88g, XREAL Light is comfortable and designed to be worn daily. Sporting a 1080P display and 52° FoV, XREAL Light offers breathtaking visual experiences.`,
      keywords: '',
      image: seoDefaulutImg,
    },
    developer: {
      title: `Developer – XREAL`,
      description: `With a consumer-friendly price tag and cross-platform compatibility, XREAL Light opens up a whole new world of possibilities for the AR/MR industry.`,
      keywords: '',
      image: seoDefaulutImg,
    },
    "nreal seeks dismissal of magic leap lawsuit": {
      title: `XREAL Seeks Dismissal of Magic Leap Lawsuit - Press - XREAL`,
      description: `XREAL, a developer of ready-to-wear mixed reality glasses, yesterday announced it has asked a federal court to dismiss Magic Leap’s lawsuit against the company and its founder, Chi Xu. XREAL is seeking a dismissal of what it contends is baseless litigation intended to slow the growth of XREAL, a nimble augmented reality innovator.`,
      keywords: '',
      image: seoDefaulutImg,
    },
  },
  ja: {
    home: {
      title: `ARグラス トップシェア - XREAL`,
      description: `XREALはARグラス世界シェアトップで急成長中のAR（拡張現実）企業です。物理的な世界とデジタルの世界を融合させるハードウェアとソフトウェアのソリューションによって、次世代のAR体験を提供します。`,
      keywords: '',
      image: seoDefaulutImg,
    },
    air2: {
      title: `XREAL Air 2 / Air 2 Pro`,
      description: `最新モデルのARグラス。より軽く、より薄く、より快適に大画面を楽しめるウェアラブルデバイスです。Proはワンタッチで3段階のレンズ調光機能が搭載。`,
      keywords: '',
      image: seoDefaulutImg,
    },
    air: {
      title: `XREAL Air`,
      description: `世界初でベストセラーの消費者向けARグラス。ゲームや映画、仕事も201インチの大画面で心地よく体験できるスマートグラスです。`,
      keywords: '',
      image: seoDefaulutImg,
    },
    beam: {
      title: `XREAL Beam`,
      description: `ARグラス専用アクセサリー。最大330インチまで仮想ディスプレイや、利用シーンに応じて3つのディスプレイ表示モードを選択できます。`,
      keywords: '',
      image: seoDefaulutImg,
    },
    about: {
      title: `XREAL - 会社紹介`,
      description: `次世代ユーザーインタラクションの構築を目指して、XREALは2017年から消費者向けの複合現実ウェアラブルデバイス開発の最前線にいます。
      `,
      keywords: '',
      image: seoDefaulutImg,
    },
    adapter: {
      title: `XREAL Adapter`,
      description: `ARグラス専用変換アダプター。アダプターとARグラスを直接接続するだけで、Switch、PS5、Xboxなどのゲームが大画面で楽しめます。
      `,
      keywords: '',
      image: seoDefaulutImg,
    },
    news: {
      title: `タイトル`,
      description: `製品のアップデートや企業情報、イベントなど、XREALに関する最新のニュースを案内します。`,
      keywords: '',
      image: seoDefaulutImg,
    },
    // 未使用
    product: {
      title: `XREAL Light - ウェアラブル複合現実グラス`,
      description: `88gに過ぎない重さと設計でXREAL Lightは、毎日着用しても快適です。1080Pディスプレイと52°FoVを誇るXREAL Lightは新次元のビジュアル体験を提供します。`,
      keywords: '',
      image: seoDefaulutImg,
    },
    developer: {
      title: `開発者 -  XREAL`,
      description: `リーズナブルな価格とクロスプラットフォームの互換性により、XREAL LightはAR/ MR業界の新しい可能性を見出します。`,
      keywords: '',
      image: seoDefaulutImg,
    },
    "nreal seeks dismissal of magic leap lawsuit": {
      title: `XREAL Seeks Dismissal of Magic Leap Lawsuit - Press - XREAL`,
      description: `XREAL, a developer of ready-to-wear mixed reality glasses, yesterday announced it has asked a federal court to dismiss Magic Leap’s lawsuit against the company and its founder, Chi Xu. XREAL is seeking a dismissal of what it contends is baseless litigation intended to slow the growth of XREAL, a nimble augmented reality innovator.`,
      keywords: '',
      image: seoDefaulutImg,
    },
    blackfriday:{
      title: `XREALブラックフライデー`,
      description: `11月18日(土)0:00から12月3日(日)23:59まで、ARグラスを特別価格で販売！大迫力のAR体験をよりお手頃な価格でお楽しみいただけます。`,
      keywords: '',
      image: seoDefaulutImg,
    }
  },
  ko: {
    home: {
      title: `XREAL - 혼합 현실 구축`,
      description: `XREAL은 모두가 경험할 수 있는 혼합 현실을 구축하기 위한 노력과 함께, 개발자가 새로운 시대를 열 수있는 엔터테인멘트 및 생산성 애플리케이션을 만들 수 있도록 합니다.`,
      keywords: '',
      image: seoDefaulutImg,
    },
    about: {
      title: `XREAL - 회사 소개`,
      description: `차세대 사용자 대화 방식을 구축하기 위해 XREAL은 2017 년부터 사용자 중심의 복합 현실 웨어러블 디바이스를 개발하는 데 앞장서 왔습니다.`,
      keywords: '',
      image: seoDefaulutImg,
    },
    news: {
      title: `언론-XREAL`,
      description: `제품 업데이트, 회사 뉴스 및 예정된 이벤트를 포함하여 XREAL에 대한 최신 뉴스를 모두 확인할 수 있습니다.`,
      keywords: '',
      image: seoDefaulutImg,
    },
    // 未使用
    product: {
      title: `XREAL Light - 웨어러블 혼합 현실 글래스`,
      description: `88g에 불과한 XREAL Light는 매일 착용해도 편안하도록 설계되었습니다. 1080P 디스플레이와 52 ° FoV를 자랑하는 XREAL Light는 전에 없던 시각적 경험을 제공합니다.`,
      keywords: '',
      image: seoDefaulutImg,
    },
    developer: {
      title: `개발자 – XREAL`,
      description: `소비자 친화적인 가격과 플랫폼간 호환성을 바탕으로, XREAL Light는 AR / MR 산업의 새로운 가능성을 제시합니다.`,
      keywords: '',
      image: seoDefaulutImg,
    },
    "nreal seeks dismissal of magic leap lawsuit": {
      title: `XREAL Seeks Dismissal of Magic Leap Lawsuit - Press - XREAL`,
      description: `XREAL, a developer of ready-to-wear mixed reality glasses, yesterday announced it has asked a federal court to dismiss Magic Leap’s lawsuit against the company and its founder, Chi Xu. XREAL is seeking a dismissal of what it contends is baseless litigation intended to slow the growth of XREAL, a nimble augmented reality innovator.`,
      keywords: '',
      image: seoDefaulutImg,
    },
  },
}
